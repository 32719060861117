@import '../../AppGlobals.css';

.answer {
  margin: 8px 0;
}

.answer li {
  margin-top: 8px;
}

.link {
  color: var(--link-color);
}

.answer b {
  font-weight: 500;
}

@media (max-width: 868px) { /* MID_TABLET_SCREEN */
  .answer ul {
    padding-right: 0px;
  }

  .answer li {
    margin-right: 25px;
  }
}
