/* src/styles/global.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --primary-color: #008080;
  --secondary-color: #1abc9c;
  --background-light: #f7f7f7;
  --background-dark: #f2f2f2;
  --text-primary: #1a202c;
  --text-secondary: #4a5568;
}

/* Base styles */
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
}

body {
  direction: rtl;
  background-color: var(--background-light);
  color: var(--text-primary);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

/* Content container for all pages */
.content-container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
}
@media (min-width: 640px) {
  .content-container {
    padding: 0 1.5rem;
  }
}

/* Ensure all text containers have proper word wrapping */
p, h1, h2, h3, h4, h5, h6, span, div {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

/* Common container utilities */
.container-sm {
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  padding: 0 1rem;
}

.container-md {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 0 1rem;
}

.container-lg {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 1rem;
}

.container-xl {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
}